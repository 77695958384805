<template>
  <div class="text-center ma-2">
    <v-snackbar
      fixed
      :value="snackbarDialog" 
      :timeout="timeout"
      :color="snackbarColor" 
      @input="showInput"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          @click="closeSnackbar()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
  export default {
    props: ['snackbarDialog', 'snackbarText', 'snackbarColor'],
    data() {
      return {
        timeout: 3000
      }
    },
    methods: {
      closeSnackbar() {
        this.$emit('closeSnackbar')
      },
      showInput() {
        this.$emit('closeSnackbar')
      }
    },
    watch: {
      snackbarDialog(value) {
        if(!value) {
          this.closeSnackbar()
        }
      }
    }
  }
</script>
