<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card :loading="loading">
      <v-card-title class="dialogColor white--text" color="secondary">
        Contacting AgentOS support
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog()" text color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-3">
        <span class="mb-3 body-1">Re: {{ details }}</span>
        <v-row class="mt-3">
          <v-col cols="12">
            <v-textarea
              prepend-inner-icon="mdi-comment-text-outline"
              v-model="msgText"
              outlined
              name="input-7-4"
              placeholder="Enter message regarding this tenant"
              label="Message"
              color="green"
            ></v-textarea>
          </v-col>
        </v-row>

        <!-- App Release 2 - 20/10/21 -->

        <v-file-input
          v-model="selectedDocuments"
          @click:clear="clearAttachedDocs"
          chips
          small-chips
          outlined
          prepend-inner-icon="mdi-paperclip"
          prepend-icon=""
          multiple
          counter
          :show-size="1000"
          label="Select multiple files for upload"
        >
        </v-file-input>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red d-inline" text @click="closeDialog()">Cancel</v-btn>
        <v-btn
          depressed
          :disabled="!msgText"
          color="green d-inline"
          width="100"
          class="mr-3 white--text"
          @click="sendMessage()"
          >Send</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "MessageDialog",
  props: {
    dialog: Boolean,
    source: String,
    details: String,
    OID: String,
  },
  data() {
    return {
      msgText: "",
      loading: false,
      selectedDocuments: [],
      attachedDocuments: [],
    };
  },
  computed: {
    // attachedDocuments() {
    //   const attachedDocuments = []
    //   this.selectedDocuments.forEach( fileObject => {
    //     const reader = new FileReader()
    //     reader.onload = () => {
    //       const baser64String = reader.result.split("base64,")[1]
    //       const documentObject = {
    //         Filename: fileObject.name,
    //         Content: baser64String,
    //       }
    //       attachedDocuments.push(documentObject)
    //     }
    //     reader.readAsDataURL(fileObject)
    //   })
    //   return attachedDocuments
    // }
  },
  methods: {
    closeDialog() {
      this.msgText = "";
      this.clearAttachedDocs();
      this.$emit("closeDialog");
    },
    clearAttachedDocs() {
      this.selectedDocuments = [];
    },
    uploadDocuments() {
      this.selectedDocuments.forEach((file) => {
        this.createBase64String(file);
      });
    },
    createBase64String(fileObject) {
      const reader = new FileReader();

      reader.onload = () => {
        // const base64String = reader.result.split("base64,")[1];
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");
        const documentObject = {
          Filename: fileObject.name,
          Content: base64String,
        };
        console.log("Attached document:", documentObject);
        this.attachedDocuments.push(documentObject);
      };

      reader.readAsDataURL(fileObject);
    },
    sendMessage() {
      this.loading = "primary darken-3";

      const payload = {
        recordID: this.OID,
        source: this.source,
        comment: this.msgText,
        attachments: this.attachedDocuments,
      };

      console.log("Message payload", payload);

      this.$store
        .dispatch("sendMessage", payload)
        .then((res) => {
          console.log("Message res", res);
          this.loading = false;
          this.msgText = "";
          this.selectedDocuments = [];
          this.attachedDocuments = [];
          this.$emit("msgSent", {
            color: "green",
            text: "Message sent",
          });
        })
        .catch((err) => {
          console.log("Msg error", err);
          this.loading = false;
          this.msgText = "";
          this.selectedDocuments = [];
          this.attachedDocuments = [];
          this.$emit("msgSent", {
            color: "red",
            text: "Error sending message!",
          });
        });
    },
  },
  watch: {
    selectedDocuments(val) {
      if (val.length) {
        this.uploadDocuments();
        console.log("value", val);
      } else this.attachedDocuments = [];
    },
  },
};
</script>

<style></style>
