<template lang="html">
  <div>
    <v-card flat>
      <v-card-title class="secondary white--text d-flex align-start pb-5">
        <div class="mt-md-0 d-md-flex align-center justify-center">
          <div v-if="returnDataSet.length" class="d-flex align-center">
            <v-icon class="" large left color="white">mdi-home-account</v-icon>
            <p class="subtitle-1 my-0">{{ returnDataSet.length }} Tenants</p>
            <v-divider vertical class="mx-3 my-1" color="white"></v-divider>
            <span class="subtitle-1">{{ totalPayments(this.returnDataSet) }}</span>
          </div>
          <v-select
            dark
            outlined dense
            class="mt-3 ml-md-5 mt-md-0 mb-md-n6"
            :items="timeInArrears"
            item-value="value"
            item-text="label"
            v-model="dataSelector"
          ></v-select>
        </div>
        <v-spacer></v-spacer>
        <TableSearch @input="(newText) => {search = newText}"  />
      </v-card-title>

      <v-data-table
        class="tableColor"
        :search="search"
        :headers="headers"
        :items="returnDataSet"
        :items-per-page="10"
        sort-by="TenantInDebtDuration"
      >
        <!-- Tenant name with info dialog -->
        <template v-slot:item.TenantName="{ item }" >
          <a class="accent--text" @click="openInfoDialog(item)">{{ item.TenantName }}</a>
          <!-- <v-btn text @click="openInfoDialog(item)" class="text-capitalize" color="primary">{{ item.TenantName }}</v-btn> -->
        </template>

        <template v-slot:item.TenantBalance="{ item }" >
  {{ item | returnDisplayTenantBalance }}
</template>

        <!-- <template v-slot:item.LastPaymentDate="{ item }" >
          {{ item.LastPaymentDate | formatDate }} 
        </template> -->

        <template v-slot:item.TenantInDebtDuration="{ item }" >
  <p class="my-0">{{ item.TenantInDebtDuration }} days</p>
  <p v-if="item.LastPaymentDate" class="my-0 font-weight-light">
    Last paid: {{ item.LastPaymentDate | formatDate }}
  </p>
</template>

        <template v-slot:item.RentAmount="{ item }">
  {{ item | returnDisplayRentAmount }} {{item.RentFrequency}}
</template>

        <template v-slot:item.actions="{ item }">
  <v-menu offset-y left>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="secondary"
        dark
        small
        depressed
        v-bind="attrs"
        v-on="on"
        class="text-capitalize"
      >
        Actions
        <v-icon right>mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list class="py-0 secondary" dark>
      <v-list-item @click="rentChase(item, 'Email')">
        <v-list-item-title
          >Email tenant regarding late payment</v-list-item-title
        >
        <v-list-item-icon>
          <v-icon small>mdi-email</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item @click="rentChase(item, 'SMS')">
        <v-list-item-title
          >SMS message tenant regarding late payment</v-list-item-title
        >
        <v-list-item-icon>
          <v-icon small>mdi-cellphone</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item @click="fetchAccountSummary(item.TenantID)">
                <v-list-item-title
                  >View account summary</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-list-item-icon>
        </v-list-item>
        <v-list-item  @click="applyCharge('Charge', item)">
                <v-list-item-title>
                  Apply Charge</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon small>mdi-currency-gbp</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item  @click="applyCharge('Refund', item)">
                <v-list-item-title>
                  Apply Refund</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon small>mdi-currency-gbp</v-icon>
                </v-list-item-icon>
          </v-list-item>

      <v-list-item @click="navigateToPaymentRequests(item)">
        <v-list-item-title>View/Create payment request</v-list-item-title>
        <v-list-item-icon>
          <v-icon small>mdi-folder-plus-outline</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item @click="openDialog(item)">
        <v-list-item-title
          >Contact support regarding this tenant</v-list-item-title
        >
        <v-list-item-icon>
          <v-icon small>mdi-comment-text-outline</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item @click="requestSTO(item)">
        <v-list-item-title>Request STO</v-list-item-title>
        <v-list-item-icon>
          <v-icon small>mdi-repeat</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item @click="requestOneOffPayment(item)">
        <v-list-item-title>Request One Off Payment</v-list-item-title>
        <v-list-item-icon>
          <v-icon small>mdi-currency-gbp</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

      </v-data-table>
    </v-card>

    <v-dialog
      v-model="emailDialog"
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="dialogColor headline white--text font-weight-light">
          Chase email
          <v-spacer></v-spacer>
          <v-icon color="white" @click="emailDialog = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="headline pt-3 title">
          Email {{emailDetails.tenant}} regarding a late payment?
        </v-card-text>
        <v-card-actions class="px-6 pb-4">
          <v-btn
            color="red darken-1"
            outlined
            @click="emailDialog = false"
          >
            No
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            @click="sendEmail"
            color="green darken-1 white--text"
            width="150"
          >
          Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="accountSummaryPopup" max-width="100%">
      <PersonAccountPopup
        :entries="accountSummary"
        @closeDialog="closeAccountSummaryPopup"
      />
    </v-dialog>
    <MsgDialog
      :dialog="dialog"
      :details="selectedItem.TenantName"
      :source="source"
      :OID="selectedItem.TenantID"
      @closeDialog="closeDialog"
      @msgSent="msgSent"
    />

    <UserInfoDialog
      :dialog="infoDialog"
      :name="selectedItem.TenantName"
      :email="selectedItem.TenantEmail"
      :number="selectedItem.TenantMobile"
      :fullDetails="selectedItem"
      :selectedProperties="selectedProperties"
      :isLoading="infoLoading"
      @closeDialog="closeInfoDialog"
    />

    <Snackbar 
      :snackbarDialog="snackbar" 
      :snackbarText="snackbarText" 
      :snackbarColor="snackbarColor"
      @closeSnackbar="closeSnackbar()"
      :timeout="timeout"
    />
    <v-dialog v-model="applyChargePopup" max-width="500px">
    <ApplyChargeOrRefundForm
        :actionToApply="chargeAction"
        :personID="selectedItem.TenantID"
        @closeDialog="closeChargePopup"
      />
    </v-dialog>
    <v-dialog v-model="requestStoDialog" max-width="500px">
    <RequestPaymentDialog
        :selectedPaymentType="'Standing Order'"
        :personID="selectedItem.TenantID"
        @closeRequestPaymentDialog="closeRequestPaymentDialog"
      />
    </v-dialog>
    <v-dialog v-model="requestOneOffDialog" max-width="500px">
    <RequestPaymentDialog
        :selectedPaymentType="'One off payment'"
        :personID="selectedItem.TenantID"
        @closeRequestPaymentDialog="closeOneOffRequestPaymentDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import MsgDialog from "@/components/Dialogs/MessageDialog";
import UserInfoDialog from "@/components/Dialogs/UserInfoDialog";
import Snackbar from "@/components/Snackbars/TheSnackbar";
import axios from "axios";
import TableSearch from "@/components/TableComponents/TableSearch.vue";
import PersonAccountPopup from "@/components/BaseComponents/PersonAccountPopup.vue"
import ApplyChargeOrRefundForm from "../PaymentRequests/ApplyChargeOrRefundForm.vue";
import RequestPaymentDialog from '../PaymentRequests/PaymentRequestDialog.vue'
export default {
  components: {
    MsgDialog,
    UserInfoDialog,
    Snackbar,
    TableSearch,
    PersonAccountPopup,
    ApplyChargeOrRefundForm,
    RequestPaymentDialog
  },
  props: [
    // 'tableData',
    "headers",
    // 'msg',
    // 'subMsg'
  ],
  data() {
    return {
      source: "Tenants in arrears",
      loading: false,
      search: "",
      selectedItem: "",
      dialog: false,
      infoDialog: false,
      msgText: "",
      snackbar: false,
      snackbarText: "",
      snackbarColor: "",
      timeout: 3000,
      selectedData: "",
      dataSelector: { label: "All", value: 1 },
      timeInArrears: [
        { label: "Show all", value: 1 },
        { label: "Under 14 days in arrears", value: 2 },
        { label: "14-31 days in arrears", value: 3 },
        { label: "32-62 days in arrears", value: 4 },
        { label: "Over 62 days in arrears", value: 5 },
      ],
      emailDetails: {
        tenant: "",
        to: "",
        subject: "",
        body: "",
      },
      emailDialog: false,
      loadingAccountsSummary: false,
      accountSummary: "",
      accountSummaryPopup: false,
      loadingChargePopup: false,
      chargeAction: "",
      applyChargePopup: false,
      requestStoDialog: false,
      requestOneOffDialog: false,
      selectedProperties: [],
      infoLoading: false
    };
  },
  computed: {
    ...mapGetters([
      "inArrearsUnder14",
      "inArrears14To31",
      "inArrears32To62",
      "inArrearsOver62",
    ]),
    ...mapState({
      inArrearsTotal: (state) =>
        state.TenantsRecords.tenantsArrearsRecords.Data,
    }),
    returnDataSet() {
      if (this.dataSelector === 1) {
        return this.inArrearsTotal;
      } else if (this.dataSelector === 2) {
        return this.inArrearsUnder14;
      } else if (this.dataSelector === 3) {
        return this.inArrears14To31;
      } else if (this.dataSelector === 4) {
        return this.inArrears32To62;
      } else if (this.dataSelector === 5) {
        return this.inArrearsOver62;
      }
      return this.inArrearsTotal;
    },
  },
  methods: {
    navigateToPaymentRequests(item) {
      this.$router.push({ name: "Payments", params: { id: item.TenantID } });
    },
    downloadStoGuide(id) {
      this.$store.dispatch("TenantsRecords/downloadStoGuide", id);
    },
    sendEmail() {
      this.emailDialog = false;
      // const bodyText = encodeURIComponent(this.emailDetails.body)
      window.location.href = `mailto:${this.emailDetails.to}?subject=${this.emailDetails.subject}&body=${this.emailDetails.body}`;
    },
    rentChase(item, actionType) {
      axios({
        method: "GET",
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/tenants/${item.TenantID}/rentchase/${actionType}`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        },
      }).then(({ data }) => {
        this.snackbarText = data.Message
        if(data.Success){
          this.snackbarColor = 'green'
        }
        else{
          this.snackbarColor = 'red'
        }
        this.snackbar = true
      });
    },
    closeSnackbar() {
      this.snackbar = false;
    },
    openDialog(item) {
      this.selectedItem = item;
      this.dialog = true;
    },
    openInfoDialog(item) {
      this.infoLoading=true;
      axios({
        method: "GET",
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/${item.TenantID}/invoices/properties`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        },
      })
        .then((response) => {
          this.selectedProperties = response.data;
          this.infoLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      this.selectedItem = item;
      this.infoDialog = true;
    },
    closeDialog() {
      this.dialog = false;
      this.msgText = "";
    },
    closeInfoDialog() {
      this.selectedProperties = [];
      this.infoDialog = false;
      this.infoDialog = false;
      this.selectedItem = "";
    },
    msgSent(payload) {
      this.dialog = false;
      (this.snackbar = true), (this.snackbarColor = payload.color);
      this.snackbarText = payload.text;
    },
    balanceOverPCM(item) {
      return (-item.TenantBalance / item.RentAmount).toFixed(1);
    },
    totalPayments(value) {
      const array = value;
      const payments = array.map((val) => {
        return val.TenantBalance;
      });
      const total = payments.reduce((acc, val) => acc + val, 0);
      // return total.toLocaleString()
      return new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      }).format(total);
    },
    fetchAccountSummary(id) {
      this.loadingPendingInvoice = true;
      axios({
        method: "GET",
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/company/person/${id}/account`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        },
      })
        .then((response) => {
          this.accountSummary = response.data;
          this.accountSummaryPopup = true;
          this.loadingAccountsSummary = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeAccountSummaryPopup() {
      (this.accountSummary = ""), (this.accountSummaryPopup = false);
    },
    applyCharge(actionToApply, item){
      this.chargeAction = actionToApply;
      this.selectedItem = item;
      this.applyChargePopup = true;
    },
    requestSTO(item){
      this.selectedItem = item;
      this.requestStoDialog = true;
    },
    requestOneOffPayment(item){
      this.selectedItem = item;
      this.requestOneOffDialog = true;
    },
    closeChargePopup(){
      this.chargeAction = "";
      this.applyChargePopup = false;
    },
    closeRequestPaymentDialog(){
      this.requestStoDialog = false;
    },
    closeOneOffRequestPaymentDialog(){
      this.requestOneOffDialog = false;
    }
  },
  created() {
    // this.selectedData = this.$store.state.TenantsRecords.tenantsArrearsRecords.Data
  },
  filters: {
    returnDisplayTenantBalance(val) {
      return val.TenantDisplayBalance;
    },
    returnDisplayRentAmount(val) {
      return val.DisplayRentAmount;
    },
  },
};
</script>
