<template>
  <div>
    <!-- Search Property component -->
    <v-row>
      <v-col cols="12" sm="6">
        <Search 
        label="Search Tenant"
        @selectedOID="fetchTenant" 
        @clearItem="clearForm"
        endpoint="tenant"
        myParams="name"
        />
      </v-col>
    </v-row>

    <v-form>
      <v-row>
        <v-col cols="12" sm="6">
          <h3>Details</h3>
          <v-text-field
            color="green"
            v-model="title"
            name="title"
            label="Title"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="firstName"
            name="firstName"
            label="Name"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="surname"
            name="surname"
            label="Surname"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="email"
            name="email"
            label="Email"
            type="email"
            :rules="emailRules"
          ></v-text-field>
          <v-text-field
            color="green"
            v-model="mobile"
            name="mobile"
            label="Mobile"
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <h3 class="grey--text">Bank Details</h3>
          <v-text-field
            color="green"
            disabled
            v-model="accountName"
            name="accountName"
            label="Account Name"
          ></v-text-field>
          <v-text-field
            color="green"
            disabled
            v-model="account"
            name="account"
            label="Account"
          ></v-text-field>
          <v-text-field
            color="green"
            disabled
            v-model="sortCode"
            name="sortCode"
            label="Sort Code"
          ></v-text-field>
          <v-text-field
            color="green"
            disabled
            v-model="statementRef"
            name="statementRef"
            label="Statement Ref"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6">
          <h3 class="grey--text">More Details</h3>
          <v-text-field
            color="green"
            disabled
            v-model="MainAccountBalance"
            name="MainAccountBalance"
            label="Main Account Balance"
          ></v-text-field>
          <v-text-field
            color="green"
            disabled
            v-model="RentOutstanding"
            name="RentOutstanding"
            label="Outstanding Rent"
          ></v-text-field>
          <v-text-field
            color="green"
            disabled
            v-model="DepositHeld"
            name="DepositHeld"
            label="Deposit Held"
          ></v-text-field>
          <v-text-field
            color="green"
            disabled
            v-model="Comments"
            name="Comments"
            label="Comments"
          ></v-text-field>
        </v-col>

        <!-- Snackbar -->
        <Snackbar 
          :snackbarDialog="snackbar"
          :snackbarText="snackbarText"
          :timeout="timeout"
          :snackbarColor="snackbarColor"
          @closeSnackbar="closeSnackbar()"
        />

      </v-row>
      <v-row>
        <v-col cols="6" align-self="end">
          <v-row>
            <v-col cols="6" offset="6">
              <v-btn :loading="loading" height="40" block color="success" @click="saveTenant()">Save</v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

  </div>
</template>

<script>
import axios from 'axios'
import Search from '@/components/CloudSearch/Search'
import Snackbar from '@/components/Snackbars/TheSnackbar'

export default {
  components: {
    Search,
    Snackbar
  },
  data() {
    return {
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      timeout: 3000,
      loading: false,
      selectedTenantID: null,
      title: '',
      firstName: '',
      middlename: '',
      surname: '',
      mobile: '',
      email: '',
      emailRules: [
        // v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      accountName: '',
      account: '',
      sortCode: '',
      statementRef: '',
      // Read only 
      MainAccountBalance: '',
      RentOutstanding: '',
      DepositHeld: '',
      Comments: ''
    }
  },
  methods: {
    closeSnackbar() {
      this.snackbar = false
    },
     fetchTenant(id) {
      this.selectedTenantID = id
      console.log('Tenant to fetch... ' + id)
      axios({
        method: 'GET',
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/tenants/${id}`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        }
      })
      .then( ({data}) => {
        console.log(data)
        this.title = data.Name.Title
        this.firstName = data.Name.Forename
        this.surname = data.Name.Surname
        this.email = data.Email
        this.mobile = data.Mobile
        this.accountName = data.BankAccount.AccountName
        this.account = data.BankAccount.AccountNumber
        this.sortCode = data.BankAccount.SortCode
        this.statementRef = data.BankAccount.LegacyBacsReference
        this.MainAccountBalance = data.MainAccountBalance
        this.RentOutstanding = data.RentOutstanding
        this.DepositHeld = data.DepositHeld
        this.Comments = data.Comments
      })
    },
    saveTenant() {
      this.loading = true
      const tenantObject = {
        // TenantOID: this.selectedTenantID,
        Name: {
          Title: this.title,
          Forename: this.firstName,
          Surname: this.surname
        },
        Mobile: this.mobile,
        Email: this.email,
        BankAccount: {
          AccountName: this.accountName,
          AccountNumber: this.account,
          SortCode: this.sortCode,
          LegacyBacsReference: this.statementRef
        }
      }
      // console.log(tenantObject)
      axios({
        method: 'PATCH',
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/tenants/${this.selectedTenantID}`,
        data: tenantObject,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        }
      })
      .then( () => {
        this.snackbar = true
        this.snackbarText = 'Tenant update successful',
        this.snackbarColor = 'green'
        this.loading = false
        this.fetchTenant(this.selectedTenantID)
      })
      .catch( (err) => {
        this.snackbar = true
        this.snackbarText = 'ERROR: ' + err.response.data,
        this.snackbarColor = 'red'
        this.loading = false
        this.fetchTenant(this.selectedTenantID)
      })
    },
    clearForm() {
      this.title = ''
      this.firstName = ''
      this.surname = ''
      this.email = ''
      this.mobile = ''
      this.accountName = ''
      this.account = ''
      this.sortCode = ''
      this.statementRef = ''
      this.MainAccountBalance = ''
      this.RentOutstanding = ''
      this.DepositHeld = ''
      this.Comments = ''
    }
  }
}
</script>

<style>
.v-text-field {
  margin: 0 auto;
}
</style>