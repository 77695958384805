<template>
    <div>
      <v-card>
        <v-card-title class="dialogColor white--text">
          <span class="headline">Account Summary</span>
          <v-spacer></v-spacer>
          <v-btn dark text @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text v-if="entries">
          <template>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th width="20%">Transaction Date</th>
                    <th width="20%">Description</th>
                    <th width="20%">Paid In</th>
                    <th width="20%">Paid Out</th>
                    <th width="20%">Balance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in entries" :key="item.OID">
                    <td>{{ item.CreatedAt | formatDate }}</td>
                    <td>{{ item.Description }}</td>
                    <td>£{{ item.PaidIn }}</td>
                    <td>£{{ item.PaidOut }}</td>
                    <td>£{{ item.Balance }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </v-card-text>
      </v-card>
    </div>
  </template>
  
  <script>

  export default {
    name: 'PersonAccountPopup',
    props: {
      entries: Array
    },
    methods: {
      closeDialog() {
        this.$emit('closeDialog')
      }
    }
  }
  </script>
  
  <style>
  
  </style>