<template lang="html">
  <div>
    <v-card flat>
      <v-card-title class="secondary white--text">
        <div class="ma-0">
          <div v-if="tableData" class="d-flex align-center">
            <v-icon large left color="white">mdi-home-account</v-icon>
            <p  class="subtitle-1 my-0">{{ tableData.Count }} Tenants <span v-if="isHistoric">| Tenant out of a tenancy with a balance</span>
            </p>
            <v-divider vertical class="mx-3 my-1" color="white"></v-divider>
            <span class="subtitle-1" v-if="showTotPayments">{{ totalPayments(this.tableData) }}</span>
          </div>
        </div>
        <v-spacer></v-spacer>
        <TableSearch @input="(newText) => {search = newText}"  />
      </v-card-title>
      <v-data-table
        class="tableColor"
        :search="search"
        :headers="headers"
        :items="tableData.Data"
        :items-per-page="10"
        sort-by="TenantInDebtDuration"
      >
        <!-- Tenant name with info dialog -->
        <template v-slot:item.TenantName="{ item }" >
          <a class="accent--text" @click="openInfoDialog(item)">{{ item.TenantName }}</a>
        </template>

        <template v-slot:item.TenantBalance="{ item }" >
          <span>
            {{ item | returnDisplayTenantBalance}} 
          </span>
        </template>

        <template v-slot:item.TenantIntoDebtDate="{ item }">
          <span>
            {{ item.TenantIntoDebtDate | formatDate }}
          </span>
        </template>

        <!-- Support message dialog with Tooltip -->
        <!-- <template v-slot:item.Message="{ item }" >
          <v-tooltip open-delay="2500" bottom>
            <template v-slot:activator="{ on }">
              <v-btn outlined small @click="openDialog(item)" v-on="on" color="secondary">
                <v-icon small>mdi-comment-text-outline</v-icon>
              </v-btn>
            </template>
            <span>Contact support regarding this tenant.</span>
          </v-tooltip>
        </template> -->

        <template v-slot:item.actions="{ item }">
          <v-menu  offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="secondary"
                dark
                small
                depressed
                v-bind="attrs"
                v-on="on"
                class="text-capitalize"
              >
                Actions
                <v-icon right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list class="py-0 secondary" dark>
              <v-list-item @click="fetchAccountSummary(item.TenantID)">
                <v-list-item-title
                  >View account summary</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon small>mdi-open-in-new</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item  @click="applyCharge('Charge', item)">
                <v-list-item-title>
                  Apply Charge</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon small>mdi-currency-gbp</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item  @click="applyCharge('Refund', item)">
                <v-list-item-title>
                  Apply Refund</v-list-item-title
                >
                <v-list-item-icon>
                  <v-icon small>mdi-currency-gbp</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="navigateToPaymentRequests(item)">
        <v-list-item-title>View payment requests</v-list-item-title>
        <v-list-item-icon>
          <v-icon small>mdi-folder-plus-outline</v-icon>
        </v-list-item-icon>
      </v-list-item>
              <v-list-item @click="openDialog(item)">
                <v-list-item-title>Contact support regarding this tenant</v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-comment-text-outline</v-icon>
                </v-list-item-icon>
              </v-list-item>
                <v-list-item @click="requestSTO(item)">
        <v-list-item-title>Request STO</v-list-item-title>
        <v-list-item-icon>
          <v-icon small>mdi-repeat</v-icon>
        </v-list-item-icon>
      </v-list-item>
      <v-list-item @click="requestOneOffPayment(item)">
        <v-list-item-title>Request One Off Payment</v-list-item-title>
        <v-list-item-icon>
          <v-icon small>mdi-currency-gbp</v-icon>
        </v-list-item-icon>
      </v-list-item>
            </v-list>
          </v-menu>
        </template>

      </v-data-table>
    </v-card>    

    <MsgDialog
      :dialog="dialog"
      :details="selectedItem.TenantName"
      :source="source"
      :OID="selectedItem.TenantID"
      @closeDialog="closeDialog"
      @msgSent="msgSent"
    />

    <Snackbar 
      :snackbarDialog="snackbar" 
      :snackbarText="snackbarText" 
      :snackbarColor="snackbarColor"
      @closeSnackbar="closeSnackbar()"
      :timeout="timeout"
    />

    <UserInfoDialog
      :dialog="infoDialog"
      :name="selectedItem.TenantName"
      :email="selectedItem.TenantEmail"
      :number="selectedItem.TenantMobile"
      :fullDetails="selectedItem"
      :selectedProperties="selectedProperties"
      :isLoading="infoLoading"
      @closeDialog="closeInfoDialog"
    />

    <v-dialog v-model="accountSummaryPopup" max-width="100%">
      <PersonAccountPopup
        :entries="accountSummary"
        @closeDialog="closeAccountSummaryPopup"
      />
    </v-dialog>
    <v-dialog v-model="applyChargePopup" max-width="500px">
    <ApplyChargeOrRefundForm
        :actionToApply="chargeAction"
        :personID="selectedItem.TenantID"
        @closeDialog="closeChargePopup"
      />
    </v-dialog>
    <v-dialog v-model="requestStoDialog" max-width="500px">
    <RequestPaymentDialog
        :selectedPaymentType="'Standing Order'"
        :personID="selectedItem.TenantID"
        @closeRequestPaymentDialog="closeRequestPaymentDialog"
      />
    </v-dialog>
    <v-dialog v-model="requestOneOffDialog" max-width="500px">
    <RequestPaymentDialog
        :selectedPaymentType="'One off payment'"
        :personID="selectedItem.TenantID"
        @closeRequestPaymentDialog="closeOneOffRequestPaymentDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import MsgDialog from '@/components/Dialogs/MessageDialog'
import UserInfoDialog from '@/components/Dialogs/UserInfoDialog'
import Snackbar from '@/components/Snackbars/TheSnackbar'
import TableSearch from '@/components/TableComponents/TableSearch.vue'
import PersonAccountPopup from "@/components/BaseComponents/PersonAccountPopup.vue"
import ApplyChargeOrRefundForm from "../PaymentRequests/ApplyChargeOrRefundForm.vue";
import RequestPaymentDialog from '../PaymentRequests/PaymentRequestDialog.vue'
import axios from "axios";

export default {
  components: {
    MsgDialog,
    UserInfoDialog,
    Snackbar,
    TableSearch,
    PersonAccountPopup,
    ApplyChargeOrRefundForm,
    RequestPaymentDialog
  },
  props: ['tableData', 'headers', 'showTotPayments', 'msg', 'isHistoric'],
  data() {
    return {
      source: 'Tenants records',
      loading: false,
      search: '',
      selectedItem: '',
      dialog: false,
      infoDialog: false,
      msgText: '',
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      timeout: 3000,
      loadingAccountsSummary: false,
      accountSummary: "",
      accountSummaryPopup: false,
      loadingChargePopup: false,
      chargeAction: "",
      applyChargePopup: false,
      requestStoDialog: false,
      requestOneOffDialog: false,
      selectedProperties: [],
      infoLoading: false
    }
  },
  methods: {
    closeSnackbar() {
      this.snackbar = false
    },
    openDialog(item) {
      this.selectedItem = item
      this.dialog = true
    },
    openInfoDialog(item) {
      this.infoLoading=true;
      axios({
        method: "GET",
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/${item.TenantID}/invoices/properties`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        },
      })
        .then((response) => {
          this.selectedProperties = response.data;
          this.infoLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
      this.selectedItem = item;
      this.infoDialog = true;
    },
    closeDialog() {
      this.dialog = false
      this.msgText = ''
    },
    closeInfoDialog() {
      this.selectedProperties = [];
      this.infoDialog = false;
      this.infoDialog = false;
      this.selectedItem = "";
    },
    msgSent(payload) {
      this.dialog = false
      this.snackbar = true,
      this.snackbarColor = payload.color
      this.snackbarText = payload.text
    },
    totalPayments(value) {
      const array = value.Data
      const payments = array.map(val => {
        return val.TenantBalance
      })
      const total = payments.reduce(
        (acc, val) => acc + val,
        0
      )
      // return total.toLocaleString()
      return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(total)
    },
    fetchAccountSummary(id) {
      this.loadingPendingInvoice = true;
      axios({
        method: "GET",
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/company/person/${id}/account`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        },
      })
        .then((response) => {
          this.accountSummary = response.data;
          this.accountSummaryPopup = true;
          this.loadingAccountsSummary = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeAccountSummaryPopup() {
      (this.accountSummary = ""), (this.accountSummaryPopup = false);
    },
    applyCharge(actionToApply, item){
      this.chargeAction = actionToApply;
      this.selectedItem = item;
      this.applyChargePopup = true;
    },
    closeChargePopup(){
      this.chargeAction = "";
      this.applyChargePopup = false;
    },
    requestSTO(item){
      this.selectedItem = item;
      this.requestStoDialog = true;
    },
    requestOneOffPayment(item){
      this.selectedItem = item;
      this.requestOneOffDialog = true;
    },
    closeRequestPaymentDialog(){
      this.requestStoDialog = false;
    },
    closeOneOffRequestPaymentDialog(){
      this.requestOneOffDialog = false;
    }
  },
    filters: {
    returnDisplayTenantBalance(val) {
      return val.TenantDisplayBalance
    }
  }
}
</script>
