<template>
  <v-dialog
      v-model="dialog"
      max-width="500"
      persistent
      scrollable
    >
      <v-card v-if="!isLoading">
        <v-card-title class="title dialogColor white--text font-weight-light py-2">
          {{ name }}
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog()" text fab small color="white">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="px-1 pt-3">
          <div v-if="fullDetails">
            <v-list>
              <v-list-item two-line v-if="fullDetails.BankAccount !== undefined || fullDetails.AccountNumber !== undefined || fullDetails.LandlordAccountNumber !== undefined">
                <v-list-item-content>
                  <v-list-item-title>Bank account</v-list-item-title>
                  <v-list-item-subtitle :class="{'red--text': fullDetails.BankAccount === 'Bank Details Incomplete' || fullDetails.AccountNumber === 'Bank Details Incomplete' || fullDetails.LandlordAccountNumber === 'Bank Details Incomplete'}">{{ fullDetails.BankAccount }}{{fullDetails.AccountNumber}}{{fullDetails.LandlordAccountNumber}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item two-line  v-if="selectedProperties.length > 0">
                <v-list-item-content>
                  <v-list-item-title>Properties</v-list-item-title>
                  <v-list-item-subtitle v-for="(property, index) in selectedProperties" :key="index">{{ property }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>

          <div v-if="email" class="email-btn mb-5">
            <v-btn color="primary" class="font-weight-regular text-lowercase" text :href="`mailto:${email}?body=Dear%20${name},`" target="_blank">
              <v-icon class="mr-3" left >mdi-at</v-icon>
                {{ email }}
                <span v-if="!email">n/a</span>
            </v-btn>
          </div>
          <div v-if="number" class="call-btn mt-5 d-flex d-sm-none">
            <v-btn color="primary" class=" font-weight-regular" text :href="`tel:${number}`">
              <v-icon class="mr-3" left >mdi-phone</v-icon>
              {{ number }}
              <span v-if="!number">n/a</span>
            </v-btn>
          </div>
          <div v-if="number" class="mt-4 sms-btn d-flex d-sm-none">
            <v-btn color="secondary" class="font-weight-regular" text :href="`sms:${number}?body=Hi%20${name}`">
              <v-icon class="mr-3" left >mdi-email-outline</v-icon>
              {{ number }}
              <span v-if="!number">n/a</span>
            </v-btn>
          </div>
          <div v-if="number" class="plain-number d-none d-sm-flex">
            <v-icon class="mx-3" left >mdi-phone</v-icon>
            <span>{{ number }}</span>
            <span v-if="!number">n/a</span>
          </div>
        </v-card-text>
      </v-card>
      <SkeletonLoaderCardVue v-else />
    </v-dialog>
</template>

<script>
import SkeletonLoaderCardVue from '../SkeletonLoaders/SkeletonLoaderCard.vue'
export default {
  props: [
    'dialog', 
    'name',
    'email',
    'number',
    'fullDetails',
    'selectedProperties',
    'isLoading'
  ],
  components:{
    SkeletonLoaderCardVue
  },
  data() {
    return {
      properties: [],
      msgText: '',
      headers: [
          {
            text: 'Properties',
            align: 'start',
            value: 'address',
          },
      ]
    }
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog')
      this.msgText = ''
    }
  },
  computed: {
    // Return first name
    // needs fix for names that are longer than firstName + Surname
    returnUserFirstName() {
      let activeUser = this.name
      var firstName = activeUser.replace(/ .*/,'')
      return firstName
    },
    indexedItems() {
      const newArray = this.fullDetails.Properties.map(item => {
        return {
          address: item
        }
      })
      console.log(newArray)
      return newArray
    },
  }
}
</script>

<style>

</style>