<template>
  <div>
    <!-- Search Property component -->
    <v-row>
      <v-col cols="12" sm="6">
        <Search 
          label="Search Tenancy"
          @selectedOID="fetchTenancy" 
          @clearItem="clearForm"
          endpoint="tenancy"
          myParams="address"
        />
      </v-col>
    </v-row>

    <v-form>
      <v-row>
        <v-col cols="12" sm="6">
          <h3>Details</h3>
          <!-- <v-text-field
            v-model="startDate"
            name="startDate"
            label="First Rent Due"
          ></v-text-field> -->
          <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          max-width="290"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                color="green"
                :value="formattedStartDate"
                clearable
                label="First rent due"
                readonly
                v-on="on"
                @click:clear="startDate = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              @change="menu1 = false"
            ></v-date-picker>
          </v-menu>
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                color="green"
                :value="formattedEndDate"
                clearable
                label="Stop Collecting"
                readonly
                v-on="on"
                @click:clear="endDate = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @change="menu2 = false"
            ></v-date-picker>
          </v-menu>
          <v-select
            color="green"
            return-object
            :items="items"
            item-text="Name"
            item-value="OID"
            v-model="serviceType"
            label="Service Type"
          ></v-select>
          <v-text-field
            color="green"
            v-model="rent"
            name="rent"
            label="Rent"
          ></v-text-field>
          <v-row>
            <v-col cols="6" offset="6">
              <v-btn :loading="loading" height="40" block color="success" @click="saveTenancy()">Save</v-btn>
            </v-col>
          </v-row>

          <!-- Snackbar indicating API request responses -->
          <Snackbar 
            :snackbarDialog="snackbar"
            :snackbarText="snackbarText"
            :timeout="timeout"
            :snackbarColor="snackbarColor"
            @closeSnackbar="closeSnackbar()"
          />
        </v-col>
      </v-row>
    </v-form>

  </div>
</template>

<script>
import axios from 'axios'
import Search from '@/components/CloudSearch/Search'
import Snackbar from '@/components/Snackbars/TheSnackbar'
import moment from 'moment/src/moment'

export default {
  components: {
    Search,
    Snackbar
  },
  data() {
    return {
      snackbar: false,
      snackbarText: '',
      snackbarColor: '',
      timeout: 3000,
      loading: false,
      selectedTenancyID: null,
      startDate: '',
      endDate: '',
      serviceType: '',
      rent: '',
      items: [],
      menu1: false,
      menu2: false
    }
  },
  methods: {
    clearForm() {
      this.startDate = ''
      this.endDate = ''
      this.serviceType = ''
      this.rent = ''
    },
    closeSnackbar() {
      this.snackbar = false
    },
    fetchTenancy(id) {
      this.selectedTenancyID = id
      axios({
        method: 'GET',
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/tenancies/${id}`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        }
      })
      .then( ({data}) => {
        this.startDate = data.StartDate.substr(0, 10)
        if(data.EndDate === null) {
          this.endDate = 'Do not stop rent collection'
        } else {
            this.endDate = data.EndDate.substr(0, 10)
        }
        this.serviceType = {
          OID: data.ServiceTypeOID,
          Name: data.ServiceType
        }
        this.rent = data.RentPerCalendarMonth
      })
    },
    saveTenancy() {
      this.loading = true
      const tenancyObject = {
        StartDate: `${this.startDate}T00:00:00`,
        EndDate: `${this.endDate}T00:00:00`,
        ServiceType: this.serviceType.OID,
        RentPerCalendarMonth: this.rent,
      }
      axios({
        method: 'PATCH',
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/tenancies/${this.selectedTenancyID}`,
        data: tenancyObject,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        }
      })
      .then( () => {
        this.snackbar = true
        this.snackbarText = 'Tenancy update successful',
        this.snackbarColor = 'green'
        this.loading = false
        this.fetchTenancy(this.selectedTenancyID)
      })
      .catch( (err) => {
        this.snackbar = true
        this.snackbarText = 'ERROR: ' + err.response.data,
        this.snackbarColor = 'red'
        this.loading = false
        this.fetchTenancy(this.selectedTenancyID)
      })
    },
  },
  computed: {
    formattedStartDate() {
      return this.startDate ? moment(this.startDate).format('DD/MM/YYYY') : ''
    },
    formattedEndDate() {
      return this.endDate ? moment(this.endDate).format('DD/MM/YYYY') : ''
    }
  },
  created() {
    axios({
      method: 'GET',
      url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/tenancies/servicetypes`,
      headers: {
        token: this.$store.state.user.LoginToken,
        api_key: this.$store.state.apiKey,
      }
    })
    .then(({data}) => {
      this.items = data
    })
  }
}

</script>

<style>
.v-text-field {
  margin: 0 auto;
}
</style>