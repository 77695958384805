<template>
<div>
    <v-card>
      <v-card-title class="dialogColor white--text">
        <span class="headline">Apply {{actionToApply}}</span>
        <v-spacer></v-spacer>
        <v-btn dark text @click="closeDialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
        <v-card-text>
          <br />
          <v-alert v-if="successMessage !== ''" type="success">
            {{successMessage}}
          </v-alert>
          <v-alert v-if="errorMessage !== ''" type="error">
            {{errorMessage}}
          </v-alert>
            <v-form ref="form">
                <v-text-field
                    label="Amount (including VAT)"
                    v-model="chargeAmount"
                    :rules="amountRules"
                  >
                  <v-icon
                    slot="prepend"
                  >
                    mdi-currency-gbp
                  </v-icon>
                </v-text-field>
                <v-select v-model="salesChargeType"
                  :items="salesChargeTypes"
                  item-text="Name"
                  item-value="OID"
                  label="Sales Charge Type"
                  :rules="serviceTypeRules"
                ></v-select>
                <v-textarea
                    v-model="transactionDescription"
                    label="Description to appear on the invoice"
                    :rules="descriptionRules"
                >
              </v-textarea>
              <v-select v-model="nominalCode"
                  :items="nominalCodes"
                  item-text="Name"
                  item-value="OID"
                  label="Nominal Code"
                ></v-select>
              <v-flex xs12 offset-xs9>
                <v-btn @click="submitCharge()" class="secondary white--text">
                  Submit
                </v-btn>
              </v-flex>
            </v-form>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import { mapState } from 'vuex'
import axios from "axios";

export default {
  name: 'applyChargeForm',
  props: {
    actionToApply: String,
    personID: String
  },
  data() {
    return {
      amountRules:[
        (v) => !!v || "An amount is required",
        (v) => /^\d+(?:\.\d{0,2})$/.test(v) || "A valid currency amount must be input, e.g. 1.00"
      ],
      serviceTypeRules:[
        (v) => !!v || "A service type is required"
      ],
      descriptionRules:[
        (v) => !!v || "A charge description is required to appear on the invoice"
      ],
        transactionDate: '',
        chargeAmount: '',
        salesChargeType: '',
        transactionDescription: '',
        nominalCode: '',
        successMessage: '',
        errorMessage: ''
    }
  },
  methods:{
    submitCharge(){
      this.errorMessage = '';
      const validForm = this.$refs.form.validate();
      if (!validForm){
        this.errorMessage = "There were issues with submitting the charge, please address validation errors on the form and try again"
      }
      else {
      axios({
        method: "POST",
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/payments/charge`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        },
        data:{
          PersonID: this.personID,
          Amount: this.chargeAmount,
          Description: this.transactionDescription,
          SalesType: this.salesChargeType,
          ActionType: this.actionToApply,
          NominalCodeID: this.nominalCode
        }
      })
        .then((response) => {
          this.transactionDate = '',
          this.chargeAmount = '',
          this.salesChargeType = '',
          this.transactionDescription= '',
          this.nominalCode =  ''
          this.successMessage = response.data;
          this.errorMessage = ''
          this.$refs.form.reset()
        })
        .catch((err) => {
          this.successMessage = ''
          this.errorMessage = err.response.data;
        });
      }
    },
    closeDialog() {
      this.$emit('closeDialog')
      this.$refs.form.reset();
      this.transactionDate = '',
          this.chargeAmount = '',
          this.salesChargeType = '',
          this.transactionDescription= '',
          this.nominalCode =  ''
          this.successMessage = '';
          this.errorMessage = ''
    }
  },
  computed: {
    ...mapState({
        salesChargeTypes: state => state.salesChargeTypes,
        nominalCodes: state => state.nominalCodes
    })
  }
}
</script>

<style>

</style>