<template>
  <div @keyup.delete="deleteBtn">
    <v-autocomplete
      color="secondary"
      outlined
      @update:search-input="isTyping = true"
      @change="chosenItem"
      @click:append-outer="clearItem"
      v-model="model"
      :search-input.sync="searchQuery"
      :label="label"
      :items="searchResult"
      item-text="Subject"
      item-value="OID"
      :hide-no-data="hideNoData"
      :no-data-text="noDataText"
      :loading="loading"
      prepend-inner-icon="mdi-magnify"
      :menu-props="{ offsetY: true }"
      append-outer-icon="mdi-close"
      :error-messages="errMsg"
      class="mt-3"
    ></v-autocomplete>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import axios from "axios";

export default {
  props: {
    endpoint: { type: String },
    label: { type: String },
    myParams: { type: String },
  },
  data() {
    return {
      model: null,
      searchQuery: null,
      isTyping: false,
      searchResult: [],
      loading: false,
      hideNoData: false,
      noDataText: "Enter a search...",
      errMsg: "",
    };
  },
  watch: {
    searchQuery: debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping(value) {
      if (this.searchQuery == null) {
        return;
      } else if (this.searchResult.length) {
        return;
      } else if (this.searchQuery.length < 4) {
        return;
      } else if (!value) {
        this.searchItem(this.searchQuery);
        this.loading = true;
      }
    },
  },
  methods: {
    changeOccuring() {
      console.log("typing happening !!!");
    },
    clearItem() {
      this.isTyping = false;
      this.searchQuery = null;
      this.model = null;
      this.searchResult = [];
      this.$emit("clearItem");
    },
    chosenItem() {
      const selectedOID = this.model;
      this.$emit("selectedOID", selectedOID);
    },
    deleteBtn() {
      // console.log('this.model === null ...')
      this.model = null;
      this.hideNoData = true;
    },
    searchItem(query) {
      // console.log('Searching for... ' + query)
      axios({
        method: "GET",
        url: `${this.$store.state.baseURL}/corporate/agentpay/letmcletting/${this.$store.state.user.ClientName}/search/${this.endpoint}?${this.myParams}=${query}`,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        },
      })
        .then(({ data }) => {
          if (data.Count > 0) {
            this.searchResult = data.Data;
            this.loading = false;
            console.log("Search successful! ");
            console.log("Search Data:", data);
          } else {
            this.loading = false;
            this.hideNoData = false;
            this.noDataText = "Ooops. We can`t find an item with this name.";
          }
        })
        .catch((err) => {
          console.log("Search FAIL!");
          this.loading = false;
          console.log(err.response.data);
        });
    },
  },
};
</script>
