<template>
  <div>
    <v-container class="py-0">
      <transition name="fade" mode="out-in" appear>
        <PageTitle class="d-block d-sm-none">Tenants</PageTitle>
      </transition>

      <transition name="slide-left-fade" mode="out-in" appear>
        <v-tabs
          v-model="tab"
          class="mt-5 mt-sm-1"
          color="tabColor"
          slider-color="tabColor"
          center-active
          background-color="tertiary"
          show-arrows
          height="55"
        >
          <v-tab ripple>
            In arrears
          </v-tab>
          <v-tab ripple>
            Not chased
          </v-tab>
          <v-tab ripple>
            Active
          </v-tab>
          <v-tab ripple>
            Historic
          </v-tab>
          <v-spacer></v-spacer>
          <span class="d-none d-sm-block">
            <PageTitle>Tenants</PageTitle>
          </span>
        </v-tabs>
      </transition>

      <transition name="slide-fade" mode="out-in" appear>
        <div>
          <v-tabs-items v-model="tab" class="pt-2 tertiary">
            <v-tab-item>
              <Skeleton v-if="!tenantsArrearsRecords" />
              <TenantsInArrearsTable
                v-if="tenantsArrearsRecords"
                :tableData="tenantsArrearsLessThan60Days"
                :headers="headersInArrears"
                msg="In arrears"
                subMsg="Under 62 day"
              />
            </v-tab-item>
            <v-tab-item>
              <Skeleton v-if="!tenantsNotChasedRecords" />
              <TenantsRecordsTable
                v-if="tenantsNotChasedRecords"
                :tableData="tenantsNotChasedRecords"
                :headers="headers"
                showTotPayments="true"
                msg="Not Chased"
                :isHistoric="false"
              />
            </v-tab-item>  
            <v-tab-item>
              <Skeleton v-if="!tenantsActiveRecords" />
              <TenantsRecordsTable
                v-if="tenantsActiveRecords"
                :tableData="tenantsActiveRecords"
                :headers="headers"
                msg="Active"
                :isHistoric="false"
              />
            </v-tab-item>
            <v-tab-item>
              <Skeleton v-if="!tenantsHistoricRecords" />
              <TenantsRecordsTable
                v-if="tenantsHistoricRecords"
                :tableData="tenantsHistoricRecords"
                :headers="headers"
                msg="Historic"
                :isHistoric="true"
              />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </transition>
      
      <transition name="slide-fade" mode="out-in" appear>

        <v-row>
          <v-col cols="12">
            <v-expansion-panels multiple class="mt-8">
              <v-expansion-panel>
                <v-expansion-panel-header ripple class="secondary lighten-3 white--text">
                  <div class="d-flex align-center justify-start">
                    <v-icon class="mr-3" color="white">mdi-pencil</v-icon>
                    Edit Tenancy
                  </div>
                  <template v-slot:actions>
                    <v-icon color="white">$expand</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <EditTenancy />
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header ripple class="secondary lighten-3 white--text">
                  <div class="d-flex align-center justify-start">
                    <v-icon class="mr-3" color="white">mdi-pencil</v-icon>
                    Edit Tenant
                  </div>
                  <template v-slot:actions>
                    <v-icon color="white">$expand</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <EditTenant />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </transition>

    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TenantsRecordsTable from '../components/Tenants/TenantsRecordsTable.vue'
import TenantsInArrearsTable from '../components/Tenants/TenantsInArrearsTable.vue'
import Skeleton from '../components/SkeletonLoaders/SkeletonLoaderTable.vue'
import EditTenancy from '@/components/Edits/EditTenancy.vue'
import EditTenant from '@/components/Edits/EditTenant.vue'
import PageTitle from '@/components/Slots/PageTitle.vue';

export default {
  components: {
    TenantsRecordsTable,
    TenantsInArrearsTable,
    Skeleton,
    EditTenancy,
    EditTenant,
    PageTitle
  },
  data() {
    return {
      tab: null,
      active: true,
      inArrears: true,
      inArrearsOver: true,
      inArrearsUnder: true,
      notChased: true,
      historic: true,
      search: '',
      headers: [
        {
          text: 'Name',
          align: 'left',
          value: 'TenantName'
        },
        { text: 'Address', value: 'TenantAddress', width: '30%' },
        { text: 'Balance', value: 'TenantBalance', width: '10%', sortable: true, sort: (a, b) => b - a },
        { text: 'Days in Debt', value: 'TenantInDebtDuration', align: 'center', sortable: true },
        { text: 'Debt Date', value: 'TenantIntoDebtDate' },
        { text: '', value: 'actions',sortable: false, align: 'end' }
        // { text: 'Support', value: 'Message' },
     ],
      headersInArrears: [
        {
          text: 'Name',
          align: 'left',
          value: 'TenantName',
        },
        { text: 'Address', value: 'TenantAddress' },
        { text: 'Balance', value: 'TenantBalance', sortable: true, sort: (a, b) => b - a, width: '15%' },
        { text: 'Rent', value: 'RentAmount', sortable: true, sort: (a, b) => b - a, width: '15%' },
        { text: 'Debt', value: 'TenantInDebtDuration', sortable: true, width: '15%' },
        { text: '', value: 'actions', sortable: false, align: 'end' }
      ]
    }
  },
  created() {
    this.$store.dispatch('fetchTenantsRecords')
    console.log(this.$store.state.TenantsRecords.tenantsHistoricRecords);
  },
  computed: {
    ...mapState({
        tenantsNotChasedRecords: state => state.TenantsRecords.tenantsNotChasedRecords, 
        tenantsArrearsRecords: state => state.TenantsRecords.tenantsArrearsRecords, 
        tenantsActiveRecords: state => state.TenantsRecords.tenantsActiveRecords,
        tenantsArrearsLessThan60Days: state => state.TenantsRecords.tenantsArrearsLessThan61Days,
        tenantsArrearsOver60Days: state => state.TenantsRecords.tenantsArrearsOver61Days,
        tenantsHistoricRecords: state => state.TenantsRecords.tenantsHistoricRecords
    })
  },
}
</script>
